<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: "app",
		components: {},
    mounted(){

    },
		methods: {

		},
	};
</script>

<style lang="less">
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: "PingFang SC", "Microsoft YaHei", "DejaVu Sans", Tahoma, "Hiragino Sans GB", STHeiti, "Microsoft YaHei";
	}

	a {
		text-decoration: none
	}

</style>
